@import url(https://fonts.googleapis.com/css?family=Raleway:400,700,900);
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700');

/*################################################################
	UI Stylesheet
################################################################# */

// { outline: 1px solid black; }

// Colors
//
// Grayscale and brand colors for use across CubeKrowd.

// Start with assigning color names to specific hex values.
$white:  #fff !default;
$black:  #000 !default;
$red:    #f44336 !default;
$orange: #ffc107 !default; //(amber)
$yellow: #ffeb3b !default;
$green:  #4caf50 !default;
$blue:   #03a9f4 !default;
$teal:   #009688 !default;
$pink:   #e91e63 !default;
$purple: #9c27b0 !default;

// Create grayscale
$gray-dark2:                 #455A64 !default; //CK
$gray-dark1:                 #37474F !default; //CK
$gray-dark:                  #263238 !default;
$gray:                       #607d8b !default;
$gray-light:                 #eceff1 !default;
$gray-lighter:               rgba(255, 255, 255, 0.5) !default;
$gray-lightest:              $white !default;

// Global stuff
body {
	background: url(/assets/img/resources/bg.jpg) fixed;
	background-position: center center;
	background-size: cover;
	font-family: 'Noto Sans', sans-serif;
	font-weight: 400;
	font-size: 1rem;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Raleway', sans-serif;
	font-weight: 700;
}

hr {
	border-top: 4px solid white;
	margin-top: 0;
}
hr.hr-danger {border-top-color: $red;}
hr.hr-warning {border-top-color: $orange;}
hr.hr-success {border-top-color: $teal;}
hr.hr-primary {border-top-color: $blue;}
hr.hr-info {border-top-color: $purple;}
hr.hr-dark {border-top-color: $gray-dark;}

.text-danger, a.text-danger:hover, a.text-danger:active, a.text-danger:focus {color: $red !important;}
.text-warning, a.text-warning:hover, a.text-warning:active, a.text-warning:focus {color: $orange !important;}
.text-success, a.text-success:hover, a.text-success:active, a.text-success:focus {color: $teal !important;}
.text-primary, a.text-primary:hover, a.text-primary:active, a.text-primary:focus {color: $blue !important;}
.text-info, a.text-info:hover, a.text-info:active, a.text-info:focus {color: $purple !important;}
.text-white, a.text-white:hover, a.text-white:active, a.text-white:focus {color: $white !important;}
.text-dark, a.text-dark:hover, a.text-dark:active, a.text-dark:focus {color: $gray-dark !important;}

.bg-danger {background-color: $red !important;}
.bg-warning {background-color: $orange !important;}
.bg-success {background-color: $teal !important;}
.bg-primary {background-color: $blue !important;}
.bg-purple {background-color: $purple !important;}
.bg-white {background-color: $white !important;}
.bg-dark {background-color: rgba(38, 50, 56, 0.97) !important;}
.bg-muted {background-color: $gray-lighter !important;}

.img-frame {
	border: 1rem solid $gray-lighter;
}

.btn-danger {
	border-color: $red;
	background-color: $red;
	border-bottom: 5px solid #d32f2f;
}

.btn-warning {
	border-color: $orange;
	background-color: $orange;
	border-bottom: 5px solid #FFA000;
}

.btn-success {
	border-color: $teal;
	background-color: $teal;
	border-bottom: 5px solid #00796B;
}

.btn-primary {
	border-color: $blue;
	background-color: $blue;
	border-bottom: 5px solid #0288D1;
}

.btn-light {
	border-color: $gray-light;
	background-color: $gray-light;
	border-bottom: 5px solid $gray;
}

.btn-dark {
	border-color: $gray-dark;
	background-color: $gray-dark;
	border-bottom: 5px solid $gray-dark1;
}

input[type=text] {
	border-color: $blue;
	background-color: $white;
	border-bottom: 5px solid #0288D1;
}

.nav a:nth-child(2n+1) li {
	background:white;
}

.not-allowed {
	cursor: not-allowed;
}

.modal-content {
	border: 0;
	border-radius: 0;
	border-left: 1rem solid $gray-dark;
}

// Header 2
#header-nav {
	background: $gray-dark;
}
#header-nav-inner {
	background: $gray-dark1;
	text-align: center;
	.a:hover, .active {
		background: $gray-dark2;
	}
}

#body-br {
	padding-bottom: 12rem;
	background-color: rgba(0, 0, 0, 0.5);
}

#main-content {
	background-color: rgba(255, 255, 255, 0.8);
}

// Footer
footer {
	color: $white;
	line-height: 30px;
	a, a:hover, a:active {
		color: white;
	}
	.fa-brands {
		padding-top: 8px;
		padding-left: 1px;
		width: 36px;
		height: 36px;
		font-size: 20px;
		text-align: center;
	}

	.stand-out {
		background-color: $gray-dark1;
	}
}

.breadcrumb {
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 0;
}

.z-index-1000 {
	z-index: 1000;
}

.right-0 {
	right: 0;
}

// Staff page
.staff-bg {
	background-color: $gray-dark;
	height: 7rem;
}

.staff-bg-admin {
	border-bottom: 5px solid $red;
}

.staff-bg-developer {
	border-bottom: 5px solid $purple;
}

.staff-bg-moderator {
	border-bottom: 5px solid $blue;
}

.staff-bg-helper {
	border-bottom: 5px solid $teal;
}

.staff-head {
	margin-top: -4rem;
	display: block;
	margin-right: auto;
	margin-left: auto;
}

// Bans page
.popover {
	border-width: 2px;
	border-color: $gray;
}

// For blog post and ban history pagination
.page-item.active .page-link {
	background-color: $blue;
	border-color: $blue;
}

.page-item .page-link {
	color: $blue;
}
